import { getGroupList} from '@/api/materialGroup';
import { messageError } from '@/utils/message';

export const materialGroupModule = {
  namespaced:true ,// 命名空间

  state: {
    GroupList: {}, 
  },

  getters: {},

  mutations: {
    //设置获取物料分类
    setGroupList: (state: { GroupList: any; }, list: any) => {
      state.GroupList = list
    },
  },

  actions: {
    getGroupData: ({commit}:any) => {
      //异步取配置信息
      return new Promise(resolve => {
        try {
          //获取物料分类
          getGroupList()
          .then((res: any) => {
            const { data } = res;
            commit('setGroupList', data)
          })
          .catch(() => {
            //Loading.close();
          });
        } catch (error) {
          messageError(`服务端异常：${JSON.stringify(error)}`);
        }
      })
    },
  },
  
  modules: {},
}