import service from '@/utils/service'

//获取列表
export function getSupplierList (params = {}) {
  return service({
    url: `material/supplier/get-list`,
    method: 'get',
    params
  })
}

//推送供应商价格
export function putSupplierPrice(data:any = {}) {
  return service({
    url: `material/supplier/put-price`,
    method: 'post',
    data
  })
}