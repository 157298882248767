import { getConfig ,getDict,getUser,getCurrency} from '@/api/basic';
import { messageError } from '@/utils/message';

export const configModule = {
  namespaced:true ,// 命名空间

  state: {
    configInfo: {}, //系统配置信息
    dictInfo: {}, //字典数据信息
    userData: {},//正常用户列表
    currencyData:{},//币种
  },

  getters: {},

  mutations: {
    //设置系统配置信息
    setConfigInfo: (state: { configInfo: any; }, info: any) => {
      state.configInfo = info
    },
    //设置字典数据信息
    setDictInfo: (state: { dictInfo: any; }, info: any) => {
      state.dictInfo = info
    },
    //设置所有正常用户信息
    setUserList: (state: { userData: any; }, info: any) => {
      state.userData = info
    },
    //设置币种
    setCurrencyList: (state: { currencyData: any; }, info: any) => {
      state.currencyData = info
    },
  },

  actions: {
    getConfiglist: ({commit}:any) => {
      //异步取配置信息
      return new Promise(resolve => {
        try {
          getConfig().then((res)=>{
            const { data } = res;
            commit('setConfigInfo', data)
          }).catch(() => {
            //Loading.close();
          });;
        } catch (error) {
          messageError(`服务端异常：${JSON.stringify(error)}`);
        }
      })
    },
    getDictList: ({commit}:any) => {
      //异步取字典数据信息
      return new Promise(resolve => {
        try {
          getDict().then((res)=>{
            const { data } = res;
            commit('setDictInfo', data)
          }).catch(() => {
            //Loading.close();
          });;
        } catch (error) {
          messageError(`服务端异常：${JSON.stringify(error)}`);
        }
      })
    },
    getUserList: ({commit}:any) => {
      //异步取用户列表
      return new Promise(resolve => {
        try {
          getUser().then((res)=>{
            const { data } = res;
            commit('setUserList', data)
          }).catch(() => {
            //Loading.close();
          });;
        } catch (error) {
          messageError(`服务端异常：${JSON.stringify(error)}`);
        }
      })
    },
    getCurrencyList: ({commit}:any) => {
      //异步取币种列表
      return new Promise(resolve => {
        try {
          getCurrency().then((res)=>{
            const { data } = res;
            commit('setCurrencyList', data)
          }).catch(() => {
            //Loading.close();
          });;
        } catch (error) {
          messageError(`服务端异常：${JSON.stringify(error)}`);
        }
      })
    }

    
  },
  
  modules: {},
}