import { messageError } from '@/utils/message';
import { getKdDict } from '@/api/basic';

export const dictModule = {
  namespaced:true ,// 命名空间

  state: {
    dictList: {}, //字典数据信息
  },

  getters: {},

  mutations: {
    //设置系统配置信息
    setDictList: (state: { dictList: any; }, data: any) => {
      state.dictList = data
    },
  },

  actions: {
    getDictList: ({commit}:any) => {
      //异步取字典数据信息
      return new Promise(resolve => {
        try {
          getKdDict({dict_type_arr: ['material_name', 'material_color', 'material_quality', 'material_description','material_edition']}).then((res)=>{
            const { data } = res;
            commit('setDictList', data)
          }).catch(() => {
            //Loading.close();
          });;
        } catch (error) {
          messageError(`服务端异常：${JSON.stringify(error)}`);
        }
      })
    },
  },
  
  modules: {},
}