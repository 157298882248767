
import { messageError } from '@/utils/message';
import { getCustomerList } from '@/api/customer';

export const customerModule = {
  namespaced:true ,// 命名空间

  state: {
    customerList: {},
  },

  getters: {},

  mutations: {
    setCustomerList: (state: { customerList: any; }, data: any) => {
      state.customerList = data
    },
  },

  actions: {
    getCustomerData: ({commit}:any) => {
      return new Promise(resolve => {
        try {
          getCustomerList().then((res)=>{
            const { data } = res;
            commit('setCustomerList', data)
          }).catch(() => {
            //Loading.close();
          });;
        } catch (error) {
          messageError(`服务端异常：${JSON.stringify(error)}`);
        }
      })
    },
  },
  
  modules: {},
}