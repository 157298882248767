type stateType = {
  navList:object
  routerNavList:object
  selectIndex:number
  tabList:any[]
  cachedViews:any[]
};
export const menuModule = {
  namespaced:true ,// 命名空间
  state: {
    navList: [], //所有导航栏列表
    routerNavList: [], //左侧展示的路由列表
    selectIndex: null, //顶部导航栏选中的index
    tabList: [
      //缓存tab列表
      {
        title: '首页',
        name: 'Home',
        authentication: true,
        keepAlive: true
      }
    ],
    cachedViews: ['Home']
  },
  getters: {},
  mutations: {
    //设置标签标题
    setPageCardName:(state:stateType, obj:any) => {
      setTimeout(() => {
        const item = state.tabList.find(el => el.name == obj.name)
        item.title = obj.title
      }, 20)
    },
    //设置所有导航栏列表
    setNavlist: (state: stateType, list: object) => {
      state.navList = list
    },
    //设置左侧展示的路由列表
    setRouterNavlist: (state:stateType, list: object) => {
      state.routerNavList = list
    },
    //设置顶部导航栏选中的index
    setSelectIndex: (state:stateType, i: number) => {
      state.selectIndex = i
    },
    //增加标签
    addTabList: (state: stateType, route: any) => {
      if (!state.tabList.find(item => item.name === route.name)) {
        state.tabList.push(route)
        state.cachedViews.push(route.name)
      }
    },
    //关闭标签
    deleteTabList: (state: stateType, index: { start: number; end: number; }) => {
      state.tabList.splice(index.start, index.end)
      state.cachedViews.splice(index.start, index.end)
    },
    //关闭所有标签
    deleteAllTabList: (state: stateType) => {
      state.tabList = [
        {
          title: '首页',
          name: 'Home',
          authentication: true,
          keepAlive: true
        }
      ]
      state.cachedViews = ['Home']
    }

  },
  actions: {},
  modules: {},
}