import service from '@/utils/service'

//获取列表
export function getGroupList (params = {}) {
  return service({
    url: `material/group/get-list`,
    method: 'get',
    params
  })
}

//获取详情
export function getInfo (params = {}) {
  return service({
    url: `material/group/edit`,
    method: 'get',
    params
  })
}

// 修改状态
export function editGroupStatus (data = {}) {
  return service({
    url: `material/group/edit-status`,
    method: 'post',
    data
  })
}

// 修改排序
export function editGroupSort (data = {}) {
  return service({
    url: `material/group/edit-sort`,
    method: 'post',
    data
  })
}

// 添加or修改
export function saveGroup (data: any = {}) {
  const url = 'material/group/edit';
  return service({
    url: url,
    method: 'post',
    data
  })
}

//删除
export function deleteGroup (params: any = {}) {
  return service({
    url: `material/group/delete`,
    method: 'get',
    params
  })
}