export const userModule = {
  namespaced:true ,// 命名空间
  state: {
    userinfo: {
      "router":[]
    }, //登陆用户信息
  },
  getters: {},
  mutations: {
    setUserInfo: (state: { userinfo: any; }, info: any) => {
      state.userinfo = info
    },
  },
  actions: {
  },
  modules: {},
}