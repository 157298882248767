import service from '@/utils/service'

// 清除缓存
export function cacheFlush () {
  return service({
    url: `basic/cache-flush`,
    method: 'get',
  })
}

// 获取配置
export function getConfig () {
  return service({
    url: `basic/config`,
    method: 'get',
  })
}

// 获取配置
export function getDict () {
  return service({
    url: `basic/dict`,
    method: 'get',
  })
}

// 获取用户数据
export function getUser () {
  return service({
    url: `basic/user-list`,
    method: 'get',
  })
}

// 获取操作日志列表
export function getLog (data: any = {}) {
  return service({
    url: `basic/operate_log`,
    method: 'post',
    data
  })
}

// 获取金碟字典数据
export function getKdDict (data: any = {}) {
  return service({
    url: `basic/kd-dict`,
    method: 'post',
    data
  })
}

//币种列表
export function getCurrency () {
  return service({
    url: `basic/currency-list`,
    method: 'get',
  })
}

